<style lang="css" scoped>
.row-pointer >>> tbody tr :hover {
    cursor: pointer;
}

.truncate-cell {
    max-width: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>
<template>
    <v-col class="mt-4" cols="12">
        <v-row align="center" class="mx-4" justify="space-between" no-gutters>
            <v-col cols="4">
                <!-- v-row no-gutters>
                    < version-picker / >
                </v-row !-->
            </v-col>

            <v-col cols="4" class="text-center">
                <v-btn outlined to="/dashboard/add">
                    Создать новый проект
                </v-btn>
            </v-col>
            <v-col cols="4"></v-col>
        </v-row>
        <v-row align="center" class="ma-4" justify="center" no-gutters>
            <v-col cols="8">
                <v-data-table
                    :headers="headers"
                    :items="projects"
                    :loading="isLoading"
                    class="elevation-1 row-pointer"
                    disable-filtering
                    disable-pagination
                    disable-sort
                    hide-default-footer
                    hide-default-header
                    loading-text="Обновляем список..."
                    no-data-text="Проекты отсутствуют. Создайте новый."
                    no-results-text="По фильтру ничего не найдено"
                >
                    <template v-slot:header="{ props }">
                        <thead v-if="projects.length">
                            <tr>
                                <th
                                    class="text--primary subtitle-2"
                                    v-for="header in props.headers"
                                    :key="header.value"
                                    style="text-align: center"
                                >
                                    {{ header.text }}
                                </th>
                            </tr>
                        </thead>
                    </template>
                    <template v-slot:item="{ item }">
                        <tr @click="editProject(item)">
                            <td style="width: 1px;" class="text-center">
                                <v-chip pill>
                                    {{ projects.indexOf(item) + 1 }}
                                </v-chip>
                            </td>
                            <td class="truncate-cell px-4 text-center">
                                {{ item.name }}
                            </td>
                            <td class="truncate-cell px-4 text-center">
                                {{ item.description }}
                            </td>
                            <td class="text-right" style="width: 50px;">
                                <v-btn
                                    icon
                                    @click.stop.prevent="removeProject(item.id)"
                                >
                                    <v-icon>mdi-delete</v-icon>
                                </v-btn>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
    </v-col>
</template>

<script>
import { AUTH_LOGOUT } from "../../store/actions/auth";
// import VersionPicker from "@/views/Dashboard/VersionPicker";

export default {
    // components: { VersionPicker },
    data: () => ({
        projects: [],
        headers: [
            {
                text: "ID",
                value: "id"
            },
            {
                text: "Название проекта",
                value: "name"
            },
            {
                text: "Краткое описание проекта",
                value: "description"
            },
            {
                value: "remove"
            }
        ],
        isLoading: true
    }),
    mounted() {
        this.getProjects();
    },
    methods: {
        getProjects() {
            this.isLoading = true;
            this.projects = [];
            this.axios
                .get("/api/projects")
                .then(resp => {
                    if (resp.data.length) this.projects = resp.data;
                    this.isLoading = false;
                    if (this.addedId) {
                        this.editProject(this.addedId);
                    }
                })
                .catch(err => {
                    if (err.response.status === 401) {
                        this.$store.dispatch(AUTH_LOGOUT).then(() => {
                            this.$router.push("/");
                        });
                    }
                });
        },
        removeProject(id) {
            this.$store.dispatch("loading", true);
            this.projects = [];
            this.axios.delete(`/api/projects/${id}`).then(() => {
                this.$store.dispatch("loading", false);
                this.getProjects();
            });
        },
        editProject(item) {
            this.$router.push(`/dashboard/${item.id}`);
        }
    }
};
</script>
